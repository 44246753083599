<!--
 * @Author: wxy
 * @Date: 2022-08-03 14:11:41
 * @LastEditTime: 2022-08-11 14:42:59
 * @Description: 服务管理职位管理列表
-->
<template>
  <div class="positionManagement">
    <ds-header title="职位管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <!-- <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.projectId"
        >
          <a-select-option value=""> 全部项目 </a-select-option>
          <a-select-option v-for="item in projectName" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <!-- <el-cascader v-model="searchForm.projectId" style="width: 200px"
              :options="projectName"
              :props="{emitPath:false,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader> -->
        <a-cascader expandTrigger="hover" style="width: 100%;" v-model="searchForm.projectId"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectName"
          :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }"
          placeholder="请选择所属项目" />
      </a-form-item>
      <a-form-item label="职位名称">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px" placeholder="请选择"
          v-model="searchForm.positionId">
          <a-select-option value=""> 全部职位 </a-select-option>
          <a-select-option v-for="item in positionName" :key="item.position_id" :value="item.position_id">
            {{ item.position_name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>
    <div style="margin: 10px 0">
      <a-button type="primary" @click="add"> <a-icon type="plus" /> 新增展示职位</a-button>
    </div>
    <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false" @change="pageChange" bordered
      :scroll="{ x: 1500 }">
      <div slot="projectName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.projectName }}</span>
          </template>
          <div :style="{
            width: '220px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }">
            {{ text.projectName }}
          </div>
        </a-tooltip>
      </div>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="isEnabled(record)" class="btn">{{ record.showStatus === 0 ? "禁用" : "启用" }}
        </a-button>
        <a-button type="link" @click="edit(record)" class="btn"> 编辑 </a-button>
        <a-button type="link" @click="deletePosition(record)" style="color: #f5222d" class="btn"> 删除 </a-button>
      </span>
    </a-table>
    <a-modal v-model="isShowDialog" :title="dialogTitle" width="600px" @ok="submit" v-if="isShowDialog">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" :rules="rules">
        <a-form-model-item label="职位名称" prop="positionId">
          <a-select :disabled="!!form.id" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            placeholder="请选择职位名称" v-model="form.positionId">
            <a-select-option v-for="item in positionName" :key="item.position_id" :value="item.position_id">
              {{ item.position_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属项目" prop="projectId">
          <!-- <a-select
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            placeholder="请选择所属项目"
            v-model="form.projectId"
          >
            <a-select-option v-for="item in projectName" :key="item.projectId" :value="item.projectId">
              {{ item.projectName }}
            </a-select-option>
          </a-select> -->
          <!-- <el-cascader style="width: 100%;" 
              v-model="form.projectId"
              :options="projectName"
              placeholder="请选择所属项目"
              :props="{emitPath:false,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable>
          </el-cascader> -->
          <a-cascader expandTrigger="hover" style="width: 100%;" v-model="form.projectId"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectName"
            :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }" placeholder="请选择所属项目"
            @change="selectedProject" />
        </a-form-model-item>
        <a-form-model-item label="状态" :span="24" prop="showStatus">
          <a-radio-group v-model="form.showStatus">
            <a-radio :value="0"> 启用 </a-radio>
            <a-radio :value="1"> 禁用 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import * as api from "@/api/entrepreneurClubManagement/positionManagement";
export default {
  name: "serverPositionManagement",
  data() {
    return {
      // 筛选条件
      searchForm: {
        // 所属项目
        projectId: [],
        //职位名称
        positionId: "",
      },
      // 项目
      projectName: [],
      // 表格
      table: {
        columns: [
          {
            title: "职位名称",
            dataIndex: "positionName",
            key: "positionName",
            width: 120,
          },
          {
            title: "所属项目",
            key: "projectName",
            width: 250,
            scopedSlots: { customRender: "projectName" },
          },
          {
            title: "状态",
            dataIndex: "showStatus",
            key: "showStatus",
            width: 120,
            customRender(text) {
              return text == 1 ? "已禁用" : "已启用";
            },
          },
          {
            title: "创建人",
            dataIndex: "createName",
            key: "createName",
            width: 120,
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: 180,
          },
          {
            title: "最后修改人",
            dataIndex: "modifiedName",
            key: "modifiedName",
            width: 120,
          },
          {
            title: "最后修改时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            width: 180,
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 弹窗标题
      dialogTitle: "",
      // 是否展示弹窗
      isShowDialog: false,
      form: {
        // 所属项目
        projectId: undefined,
        // 职位名称
        positionId: undefined,
        // 职位状态
        showStatus: 0,
      },
      rules: {
        positionId: [{ required: true, message: "请选择职位名称", trigger: "change" }],
        showStatus: [{ required: true, message: "请选择职位状态", trigger: "change" }],
        projectId: [{ required: true, message: "请选择所属项目", trigger: "change" }],
      },
      // 职位
      positionName: [],
    };
  },
  mounted() {
    // 获取列表
    this.getList();
    // 获取职位
    this.getAllPosition();
    // 获取项目
    this.getProject();
  },
  methods: {
    // 查询
    search() {
      this.table.pagination.pageSize = 10;
      this.table.pagination.current = 1;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 所属项目
        projectId: [],
        //职位名称
        positionId: "",
      };
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 获取列表
    async getList() {
      try {
        const obj = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
          projectId:this.searchForm.projectId.length?this.searchForm.projectId[this.searchForm.projectId.length-1]:''
        };
        const res = await api.serverPositionList(obj);
        if (res.code === "200") {
          this.table.tableData = res.data.positionList;
          this.table.pagination.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) { }
    },
    // 新增展示职位
    add() {
      this.form = {
        // 所属项目
        projectId: undefined,
        // 职位名称
        positionId: undefined,
        // 职位状态
        showStatus: 0,
      };
      this.dialogTitle = "新增展示职位";
      this.isShowDialog = true;
    },
    // 删除
    deletePosition(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除",
        content: "删除后该数据无法恢复，确认删除吗？",
        async onOk() {
          try {
            const res = await api.serverPositionDelete(record.id);
            if (res.code === "200") {
              _this.table.pagination.current = pageRules.pageRules(
                _this.table.tableData.length,
                1,
                _this.table.pagination.current
              );
              _this.$message.success("删除成功!");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {
            _this.$message.error("删除失败");
          }
        },
        onCancel() { },
      });
    },
    // 禁用/启用
    isEnabled(record) {
      const _this = this;
      let title;
      let content;
      let showStatus = 0;
      if (record.showStatus === 1) {
        title = "确认启用该展示职位";
        content = "";
        showStatus = 0;
      } else {
        title = "确认禁用该展示职位";
        content = "";
        showStatus = 1;
      }
      _this.$confirm({
        title: title,
        content: content,
        async onOk() {
          try {
            const res = await api.serverPositionEnable({ id: record.id, showStatus: showStatus });
            if (res.code === "200") {
              _this.$message.success("操作成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) { }
        },
        onCancel() { },
      });
    },

    // 编辑
    edit(record) {
      this.dialogTitle = "编辑展示职位";
      this.isShowDialog = true;
      this.getDetail(record.id);
    },
    // 获取详情
    async getDetail(id) {
      try {
        const res = await api.serverPositionDetail(id);
        if (res.code === "200") {
          this.form.id = res.data.id;
          this.form.showStatus = res.data.showStatus;
          this.form.positionId = res.data.positionId;
          this.form.projectId = res.data.parentProjectIdList;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) { }
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 确定
    submit() {
      const obj = {
        ...this.form,
        projectId: this.form.projectId[this.form.projectId.length - 1]
      };
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            try {
              const res = await api.serverPositionEdit(obj);
              if (res.code === "200") {
                this.$message.success("编辑成功");
                this.isShowDialog = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) { }
          } else {
            try {
              const res = await api.serverPositionAdd(obj);
              if (res.code === "200") {
                this.$message.success("新增成功");
                this.isShowDialog = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) { }
          }
        } else {
        }
      });
    },
    // 获取所有职位
    async getAllPosition() {
      try {
        const res = await api.getAllPosition();
        if (res.code === "200") {
          this.positionName = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) { }
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject();
        if (res.code === "200") {
          this.projectName = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {

        console.log('=-=======================>', error);
      }
    },
    selectedProject() {

    }
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
</style>